import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./AreaGuides.scss"
import "react-multi-carousel/lib/styles.css"
import ReportImg from "../../../images/property-report.jpg"
import PlayVideo from "../../Play/PlayVideo"
import getVideoId from "get-video-id"
const BuyersReportMobile = props => {
  // window.scrollTo(0, 0);
  const [isPlay, setPlay] = useState(false)
  const [isPlaying, setPlaying] = useState(false)
  const videoClick = value => {
    props.handleClick(value)
  }
  const videoid =
    props.VideoURL &&
    props.VideoURL[0] &&
    getVideoId(props.VideoURL[0].Youtube_Video_URL)

  return (
    <React.Fragment>
      <section>
        <div className={`buyers-report-image mobile-view`}>
          <a
            to="#"
            className="video-arrow"
            onClick={e => {
              setPlay(true)
              videoClick("video-open")
            }}
          >
            <i class="icon-video-white"></i>
          </a>
        </div>
      </section>
      {videoid && (
        <PlayVideo
          isOpen={isPlay}
          isCloseFunction={e => {
            setPlay()
            videoClick("")
          }}
          videoId={
            props.VideoURL &&
            props.VideoURL[0] &&
            props.VideoURL[0].Youtube_Video_URL
          }
          isAutoPlay={1}
        />
      )}
    </React.Fragment>
  )
}

export default BuyersReportMobile
