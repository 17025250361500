import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap"
import "./ValuationStepsBanner.scss"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import ReactMarkdown from "react-markdown/with-html"
import PlayVideo from "../../Play/PlayVideo"
import BuyersReportVideo from "../../PropertyManagement/AreaGuides/BuyersReportVideo"
import BuyersReportMobile from "../../PropertyManagement/AreaGuides/BuyersReportMobile"

const ValuationStepsBanner = props => {
  const GET_OFFICE = gql`
    query officedetails($ID: String!) {
      ourOffices(where: { id: $ID }) {
        Office_Name
      }
    }
  `
  const location = useLocation()
  const [isPlay, setPlay] = useState(false)

  const searchParams = parse(location.search) // => {init: "true"}
  var officeid = searchParams.oid
  const { loading, error, data } = useQuery(GET_OFFICE, {
    variables: { ID: officeid },
  })
  if (loading) return <p className="d-none">Loading ...</p>

  const youtubeVideo = props.VideoURL
  return (
    <React.Fragment>
      <Container className="container-banner">
        <Row>
          {props.title === "Buyers Report" && (
            <Col>
              <BuyersReportMobile VideoURL={youtubeVideo} />
            </Col>
          )}
          <Col lg={12}>
            <ScrollAnimation
              animateOnce={true}
              duration={0.5}
              animateIn="fadeInUp"
            >
              {/* {props.youtubeVideo || props.youtubeVideo && props.title === "Buyers Report" || props.youtubeVideo && props.title === "Tenant Report" ? (
                <div className="buyers-report-video">
                  <a
                    href="javascript:;"
                    className="play-btn"
                    onClick={e => {
                      setPlay(true)
                    }}
                  >
                    <i className="icon-video"></i>
                  </a>
                </div>
              ) : null} */}
              {officeid && data.ourOffices[0].Office_Name ? (
                <h4 className="small-heading">
                  Contact {data.ourOffices[0].Office_Name} Office
                </h4>
              ) : props.title === "Buyers Report" ||
                props.title === "Residents Report" ? (
                ""
              ) : (
                <h4 className="small-heading">{props.caption}</h4>
              )}
              <h1>{props.title}</h1>
              {props.youtubeVideo ||
              (props.youtubeVideo && props.title === "Buyers Report") ||
              (props.youtubeVideo && props.title === "Residents Report") ? (
                <p className="banner-p-content">
                  Please fill out the following form to generate your report, or{" "}
                  <a
                    href="javascript:;"
                    className="play-btn-content"
                    onClick={e => {
                      setPlay(true)
                    }}
                  >
                    watch our video
                  </a>{" "}
                  that explains everything you need to know about the report.
                </p>
              ) : (
                <p className="banner-p-content">
                  <ReactMarkdown
                    source={props.description}
                    escapeHtml={false}
                  />
                </p>
              )}

              {props.youtubeVideo && (
                <PlayVideo
                  isOpen={isPlay}
                  isCloseFunction={setPlay}
                  videoId={props.youtubeVideo}
                  isAutoPlay={1}
                />
              )}
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
export default ValuationStepsBanner
