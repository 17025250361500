import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import GoogleMapReact from "google-map-react"
import LocRatingMap from "./map/loc-rating-map"
import { MapMarkerInfoBox } from "./map/map-marker"

/**
 * Assets
 */

/**
 * Components
 */
import Marker from "./marker/marker"

const PropertyLocation = props => {
  // Get Map Details
  let mapItems = []
  let mapItem = {}
  mapItem["lat"] = props.Latitude
  mapItem["lng"] = props.Longitude
  const mapMarkerImageShowFlag = 0
  let mapItemHtml = MapMarkerInfoBox(props.Title, mapMarkerImageShowFlag)
  mapItem["html"] = mapItemHtml
  mapItems.push(mapItem)

  const styles = {
    control: styles => ({
      ...styles,
      backgroundColor: null,
      border: 0,
      outline: 0,
      boxShadow: "none",
      color: "#5D9272",
    }),
    option: styles => ({
      ...styles,
    }),
    placeholder: () => null,
    dropdownIndicator: styles => ({
      ...styles,
      color: "#5D9272",
    }),
    indicatorsContainer: styles => ({
      ...styles,
      color: "#5D9272",
    }),
    indicatorSeparator: () => null,
    singleValue: styles => ({
      ...styles,
      color: "#5D9272",
    }),
  }

  return (
    <div
      className={"property-location detail-office-map " + props.class}
      data-aos="fade-up"
    >
      <Col lg="12" id="map" className="order-lg-1 map-holder">
        <div>
          <LocRatingMap
            data={mapItems}
            id={props.id}
            type={props.type}
            zoom={16}
          />
        </div>
      </Col>
    </div>
  )
}

export default PropertyLocation
