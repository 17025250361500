import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
    Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./PropertyManagementGoogleReview.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import $ from "jquery"
import axios from "axios"
import ReactMarkdown from "react-markdown/with-html";
import ShowMoreText from 'react-show-more-text';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import {isMobile, isMobileOnly} from 'react-device-detect';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import Modal from 'react-bootstrap/Modal'
import GetRating from '../../getrating'
const postsPerPage = 5;
let arrayForHoldingPosts = [];



const PropertyManagementGoogleReview = (props) => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
      };
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const [reviewid, setReviewid] = useState(false);

const handleShow = (event) => {
setReviewid(event);
setShow(true);
}

    const formatter = buildFormatter(frenchStrings)

    const [active, setActive] = useState('More');

    const handleAccordionClick = () => {
        if (active === 'More') {
            setActive('Less')
        } else {
            setActive('More')
        }
    }
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(5);

    const [testimonials, setTestimonials] = useState([])

    const loopWithSlice = (start, end) => {
        const slicedPosts = testimonials.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
      };
    
      const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
      };

      
    // const getitems = async url => {
    //     try {
    //         const { data } = await axios.get(url, {
    //         })// could be from env files
    //         setTestimonials(data)
    //     } catch (error) {
    //         // cache it if fail/error;
    //         console.error(error)
    //     }
    // }


    useEffect(() => {
        loopWithSlice(0, postsPerPage);
      }, [testimonials]);
    
    
    let [mobile, setMobile] = useState(false);

    // useEffect(() => {

    //     let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    //     getitems(url);


    // }, [])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 3000, min: 1520 },
            items: 3,
        },

        desktop: {
            breakpoint: { max: 1519, min: 1024 },
            items: 3,
            partialVisibilityGutter: 65,
        },

        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
        },

        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
        },
    };
    return (
        <React.Fragment>
<Container>
<Row>
                  <Col md="12">
                  <div className="slider-holder mobile">
                <Slider {...settings}>
            {props.testimonials?.map((review, index) => {
            if(index <=10 && review.comment !== undefined) {
            return<>
            <div className="item mobil-wrap-review">
                                        <div className="review-content">
                                            <div className="reviewer-block">
                                                <div className="reviewer-image">
                                                    <picture>
                                                        <source media="(min-width:992px)" srcSet={review.reviewer.profilePhotoUrl} />
                                                        <source media="(min-width:768px)" srcSet={review.reviewer.profilePhotoUrl} />
                                                        <img src={review.reviewer.profilePhotoUrl} alt="" />
                                                    </picture>
                                                </div>
                                                <div className="reviewer-detail">
                                                    <span className="name">{review.reviewer.displayName}</span>
                                                    <span className="review-date">
                                                    <TimeAgo date={review.createTime} formatter={formatter} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="review-feedback">
                                                <GetRating starRating={review.starRating} />
                                                <p>
            {review.comment && review.comment.substring(0, 200)}
            {review.comment && review.comment.length > 200 ?'... ':''}
            {review.comment && review.comment.length > 200 &&
            <a href="javascript:void(0);" className="btn-text-link" onClick={() => handleShow(review.id)}>More</a>
            }

            {reviewid && reviewid == review.id &&
                <>
                    <Modal
                        show={show}
                        size="lg"
                        centered={true}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w modal-form reviewPopup">
                        <Modal.Header closeButton className="contact-close-btn">
                            <Modal.Title className="w-100">{review.reviewer.displayName}
                            </Modal.Title>                            
                        </Modal.Header>
                        <Modal.Body>
                            <span className="review-date">
                                <TimeAgo date={review.createTime} formatter={formatter} />
                            </span>
                            <GetRating starRating={review.starRating} />
                            <div className="review-cmt-modal">
                                {review.comment}
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </p>
                                            </div>
                                        </div>
                                    </div>
            
            </>
            }
            })}
            </Slider>
            </div>
                  </Col>

{/* 
                            {postsToShow.map((review, index) => {
                        if (review.starRating === "FOUR" || review.starRating === "FIVE") {

                                return <>
                                <div className="col-md-6">
                                    <div className="item mobil-wrap-review">
                                        <div className="review-content">
                                            <div className="reviewer-block">
                                                <div className="reviewer-image">
                                                    <picture>
                                                        <source media="(min-width:992px)" srcSet={review.reviewer.profilePhotoUrl} />
                                                        <source media="(min-width:768px)" srcSet={review.reviewer.profilePhotoUrl} />
                                                        <img src={review.reviewer.profilePhotoUrl} alt="" />
                                                    </picture>
                                                </div>
                                                <div className="reviewer-detail">
                                                    <span className="name">{review.reviewer.displayName}</span>
                                                    <span className="review-date">
                                                    <TimeAgo date={review.createTime} formatter={formatter} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="review-feedback">
                                                <GetRating starRating={review.starRating} />
                                                <p>
            {review.comment && review.comment.substring(0, 200)}
            {review.comment && review.comment.length > 200 ?'... ':''}
            {review.comment && review.comment.length > 200 &&
            <a href="javascript:void(0);" className="btn-text-link" onClick={() => handleShow(review.id)}>More</a>
            }

            {reviewid && reviewid == review.id &&
                <>
                    <Modal
                        show={show}
                        size="lg"
                        centered={true}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w modal-form reviewPopup">
                        <Modal.Header closeButton className="contact-close-btn">
                            <Modal.Title className="w-100">{review.reviewer.displayName}
                            </Modal.Title>                            
                        </Modal.Header>
                        <Modal.Body>
                            <span className="review-date">
                                <TimeAgo date={review.createTime} formatter={formatter} />
                            </span>
                            <GetRating starRating={review.starRating} />
                            <div className="review-cmt-modal">
                                {review.comment}
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </>
                                
                                
                                }
                            })}
                            <button className="btn btn-outline btn-pagination" onClick={handleShowMorePosts}>Load More</button> */}
                            </Row>
                 </Container>     
        </React.Fragment>
    );
};

export default PropertyManagementGoogleReview;
